import { CR_PRODUCT_TYPES } from '../../../constants/commerceItem';
import { isEmptyObject } from '@oracle-cx-commerce/utils/generic';
import { isOnlySubscription } from '../isSubscription';
import { isTrueObject } from '../object';

const RMCS_CATEGORY_ID = 'rmcsEnabled';

const getProduct = (products, { productId }) => (isTrueObject(products) && products[productId]) || {};

/**
 * Retrieves the value of a dynamic property from an item.
 *
 * @param {Object} item - The item object.
 * @param {string} name - The name of the dynamic property.
 * @returns {*} The value of the dynamic property, or null if not found.
 */
export const getDynamicProperty = (item, name) => item?.dynamicProperties?.find(prop => prop.id === name)?.value || null;

/**
 * Retrieves the value of a specific SKU property from an item.
 *
 * @param {Object} item - The item object.
 * @param {string} name - The name of the SKU property to retrieve.
 * @returns {any} The value of the SKU property, or null if not found.
 */
export const getSkuProperty = (item, name) => {
  const property = item?.skuProperties?.find(foundProperty => foundProperty.id === name);
  if (!property) {
    return null;
  }
  return property.value;
};

/**
 * Checks if the given item is a CR (Commerce Reference) item.
 * @param {Object} item - The item to check.
 * @returns {boolean} - Returns true if the item is a CR item, false otherwise.
 */
export const isCRItem = item => {
  const itemType = (item?.hasOwnProperty('msiItemType') && item.msiItemType) || (item?.hasOwnProperty('skuProperties') && getSkuProperty(item, 'msiItemType'));
  return CR_PRODUCT_TYPES.includes(itemType);
};

/**
 * Checks if an item is a subscription item.
 *
 * @param {Object} item - The item to check.
 * @returns {boolean} - Returns true if the item is a subscription item, false otherwise.
 */
export const isSubscriptionItem = item =>
  (item?.hasOwnProperty('msiMayContainSubscription') && !!item.msiMayContainSubscription) ||
  (item?.hasOwnProperty('dynamicProperties') && !!getDynamicProperty(item, 'msiMayContainSubscription'));

/**
 * Checks if an item is a rmcs enabled item.
 *
 * @param {Object} item - The item to check.
 * @returns {boolean} - Returns true if the item is a RMCS item, false otherwise.
 */
export const isRMCSItem = (item, products = {}) =>
  (item?.hasOwnProperty('parentCategories') && Array.isArray(item.parentCategories) && item.parentCategories.find(category => category.repositoryId === RMCS_CATEGORY_ID)) ||
  getProduct(products, { productId: item?.productId })?.parentCategories?.find(category => category.repositoryId === RMCS_CATEGORY_ID);

/**
 * Checks if any commerce item in the order satisfies the given condition.
 *
 * @param {Object} order - The order object.
 * @param {Function} condition - The condition function to be applied on each commerce item.
 * @param {Any} conditionArguments - Other arguments which will be passed to the condition function.
 * @returns {boolean} - Returns true if any commerce item satisfies the condition, false otherwise.
 */
export const checkCommerceItemsAgainstCondition = (order, condition, ...conditionArguments) => {
  if (!order?.commerceItems || isEmptyObject(order?.commerceItems)) {
    return false;
  }
  const items = Object.values(order.commerceItems);
  for (const item of items) {
    if (condition(item, ...conditionArguments)) {
      return true;
    }
  }
  return false;
};

/**
 * Checks if there is a subscription item in the given order.
 *
 * @param {Object} order - The order object to check.
 * @returns {boolean} - Returns true if there is a subscription item in the order, false otherwise.
 */
export const isSubscriptionItemInOrder = order => checkCommerceItemsAgainstCondition(order, isSubscriptionItem);
/**
 * Checks if an item is a non-shippable item based on the 'msiShippable' property.
 *
 * @param {Object} item - The item to check.
 * @returns {boolean} Returns true if the item is non-shippable, false otherwise.
 */

export const isMsiNonShippableItem = item => getSkuProperty(item, 'msiShippable') === false;

/**
 * Checks if there is a rmcs enabled item in the given order.
 *
 * @param {Object} order - The order object to check.
 * @returns {boolean} - Returns true if there is a rmcs enabled item in the order, false otherwise.
 */
export const isRMCSItemInOrder = (order, products) => checkCommerceItemsAgainstCondition(order, isRMCSItem, products);

/**
 * Checks if a CR item is present in the given order.
 *
 * @param {Object} order - The order object to check.
 * @returns {boolean} - Returns true if a CR item is found in the order, otherwise false.
 */
export const isCRItemInOrder = order => checkCommerceItemsAgainstCondition(order, isCRItem);

/**
 * Checks if an order contains only subscription products.
 *
 * @param {Object} order - The order object.
 * @returns {boolean} - Returns true if the order contains only subscription products, false otherwise.
 */
export const isSubscriptionOnlyOrder = order => {
  const { commerceItems = {} } = order;

  return !isEmptyObject(commerceItems) && Object.values(commerceItems).every(product => isOnlySubscription(product));
};
/**
 * Checks if an item is a subscription item or a non-shippable item.
 *
 * @param {Object} item - The item to check.
 * @returns {boolean} - Returns true if the item is a subscription item or a non-shippable item, otherwise returns false.
 */
export const isSubscriptionOrNonShippable = item => isOnlySubscription(item) || isMsiNonShippableItem(item);

/**
 * Checks if an order contains only non-physical products.
 *
 * @param {Object} order - The order object to check.
 * @returns {boolean} - Returns true if the order contains only non-physical products, false otherwise.
 */
export const isOrderContainingOnlyNonPhysicalProducts = order => {
  const { commerceItems = {} } = order;

  return !isEmptyObject(commerceItems) && Object.values(commerceItems).every(isSubscriptionOrNonShippable);
};
