const booleanFromString = string => {
  const text = string.trim();
  if (!Number.isNaN(parseInt(text, 10))) {
    return Boolean(parseInt(text, 10));
  }

  switch (text.toLowerCase()) {
    case '':
    case 'false':
      return false;
    case 'true':
      return true;
    default:
      return undefined;
  }
};

export const resolveBoolean = value => {
  switch (typeof value) {
    case 'number':
    case 'bigint':
      return Boolean(value);

    case 'string':
      return booleanFromString(value);

    case 'boolean':
      return value;

    default:
      return undefined;
  }
};
