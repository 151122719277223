export const GEOLOCATION_MAPPING = {
  GeoLocationUS: 'US',
  GeoLocationUSAlaskaHawaii: 'US-AlaskaHawaii',
  siteCA: 'CA',
  siteUK: 'UK',
  siteDE: 'DE',
  siteFR: 'FR',
  siteRU: 'RU',
  siteEMEA: 'EMEA',
  siteAPAC: 'APAC',
  siteLA: 'LA',
};

export const DEFAULT_GEOLOCATION = 'US';
