import { DEFAULT_GEOLOCATION } from '../../../constants/geolocation';
import { getShopperGeolocation, isUSShopper } from '../row-core';
import { DISPLAY_NAME_NO_SHIPPING, TAX_CODE_FREE_SHIPPING } from '../../../constants/noShippingMethod';
import { isOrderContainingOnlyNonPhysicalProducts } from '../../../core/common/commerceItem';
import { getCurrentOrder } from '@oracle-cx-commerce/commerce-utils/selector';

const AUDIENCE_REGION_MAPPING = {
  // any state from the "mainland" US can be used since they will always have the same shipping region
  US: 'US-IL',
  'US-AlaskaHawaii': 'US-AK',
};

/**
 * Check if shipping method is available based on criteria
 *
 * @param {Object} shippingMethod
 * @param {Boolean} isShippable
 * @param {String} shippingRegionName
 * @returns {Boolean} Is shipping method available
 */
const isShippingMethodAvailable = (shippingMethod, isShippable, shippingRegionName) => {
  const isFreeShippingMethod = shippingMethod.displayName === DISPLAY_NAME_NO_SHIPPING || shippingMethod.taxCode === TAX_CODE_FREE_SHIPPING;

  // Exclude free shipping when item or order is shippable
  if (isFreeShippingMethod && isShippable) {
    return false;
  }

  // Check region for shiping method
  const { shipToLocations } = shippingMethod;

  for (const location of shipToLocations) {
    const { shippingCountryRegion } = location;
    const isRegionInLocationRegions = !!shippingCountryRegion.find(item => item.regions.find(region => region.repositoryId === shippingRegionName));

    if (isRegionInLocationRegions) {
      return true;
    }
  }

  return false;
};

/**
 * Returns the cheapest shipping method for a given region.
 *
 * @param {Array} shippingMethods - The array of available shipping methods.
 * @param {Object} state - The state object containing the current order and shopper information.
 * @param {Object} msiShippable - The MSI shippable object.
 * @returns {Object} The cheapest shipping method for the region.
 */
export const getCheapestShippingForRegion = (shippingMethods, state, msiShippable = null) => {
  const order = getCurrentOrder(state);
  const isShippable = msiShippable || !isOrderContainingOnlyNonPhysicalProducts(order);

  // Get shipping region - For EMEA users we are forcing US
  const regionForMapping = isUSShopper(state) ? getShopperGeolocation(state) : DEFAULT_GEOLOCATION;
  const shippingRegionName = AUDIENCE_REGION_MAPPING[regionForMapping];

  // Get available shipping methods and sort by amount
  const availableShippingMethods = shippingMethods
    .filter(shippingMethod => isShippingMethodAvailable(shippingMethod, isShippable, shippingRegionName))
    .sort((a, b) => a.ranges[0]?.amount - b.ranges[0]?.amount);

  return availableShippingMethods[0];
};
