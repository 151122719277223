import { isEmptyObject } from '@oracle-cx-commerce/utils/generic';

export const returnObjectIfNotEmpty = object => (!isEmptyObject(object) ? object : undefined);

export const isObjectWithNoValues = obj => !Object.values(obj).find(val => val !== null && val !== undefined);

export const isTrueObject = obj => typeof obj === 'object' && !Array.isArray(obj) && obj !== null;

export const areTwoObjectsIdentical = (obj1 = {}, obj2 = {}) => {
  let areObjectsIndentical = true;

  Object.keys(obj1).forEach(key => {
    if (!obj2.hasOwnProperty(key) || obj1[key] !== obj2[key]) {
      areObjectsIndentical = false;
    }
  });
  Object.keys(obj2).forEach(key => {
    if (!obj1.hasOwnProperty(key) || obj2[key] !== obj1[key]) {
      areObjectsIndentical = false;
    }
  });
  return areObjectsIndentical;
};
